<template>
  <v-app class="app-container">
    <div style="position: fixed; right: 0; top: 1.5em; z-index: 11">
      <v-btn
        class="mx-2"
        fab
        light
        @click="tabs_visible = !tabs_visible"
        color="white"
      >
        <v-icon color="black"> mdi-format-list-bulleted-square </v-icon>
      </v-btn>
    </div>
    <div
      :class="{
        'tabs-visible': tabs_visible,
      }"
      class="tabs"
      style="box-shadow: 0 0 10px"
    >
      <div style="background: #f5f5f5; display: flex; place-content: left">
        <v-btn
          v-if="countries.length > 1"
          @click="open_dialog()"
          color="black"
          text
          style="margin: 0; top: 2em"
        >
          {{ lang }}
        </v-btn>
      </div>
      <v-tabs
        v-if="!showAlergens"
        fixed-tabs
        v-model="tab"
        vertical
        background-color="white"
        color="primary"
        style="padding-top: 4em"
      >
        <div
          v-for="(category, catIndex) in categories"
          v-bind:key="catIndex"
          style="margin: 0; overflow: auto"
        >
          <v-tab v-if="categoryVisible(category)" @click="tabs_visible = false">
            {{ category[lang] ? category[lang].name : "" }}</v-tab
          >
        </div>
      </v-tabs>
    </div>

    <v-container fluid style="padding: 0">
      <v-row justify="center" class="primary header" no-gutters>
        <v-col cols="12" style="min-height: 100px">
          <v-row
            class="w-100"
            style="padding: 5px"
            align="center"
            justify="center"
            no-gutters
          >
            <v-btn
              v-if="showAlergens"
              @click="showAlergens = false"
              class="back-btn"
              color="white"
              text
              >{{ $t("back") }}</v-btn
            >
            <v-img
              v-if="this.imgUrl"
              :src="this.imgUrl"
              height="85"
              max-width="200"
              contain
            ></v-img>

            <div
              class="header-title"
              v-if="this.imgUrl == null || this.imgUrl == ''"
            >
              {{ this.company_name }}
            </div>
          </v-row>
        </v-col>
        <v-tabs
          v-if="!showAlergens"
          fixed-tabs
          v-model="tab"
          background-color="white"
          color="primary"
        >
          <v-tab
            v-for="(category, catIndex) in categories"
            v-bind:key="catIndex"
            >{{ category[lang] ? category[lang].name : "" }}</v-tab
          >
        </v-tabs>
      </v-row>
      <transition name="fade">
        <v-row
          v-if="showAlergens"
          justify="center"
          style="
            position: absolute;
            left: 0;
            right: 0;
            margin-top: 100px;
            padding-left: 12px;
          "
        >
          <div class="alergen-container v-window-item">
            <alergens :lang="lang"></alergens>
          </div>
        </v-row>
      </transition>
      <v-tabs-items v-if="!showAlergens" v-model="tab" class="tab-items">
        <v-tab-item
          class="mx-auto"
          v-for="(category, catIndex) in categories"
          v-bind:key="catIndex"
        >
          <span v-if="categoryVisible(category)">
            <menu-items
              :category="category"
              :images="images"
              :lang="lang"
              @show-allergens="showAlergens = true"
            ></menu-items>
            <v-row
              no-gutters
              v-for="subcat in subcategories(category.id)"
              v-bind:key="subcat.id"
            >
              <div v-if="categoryVisible(subcat)">
                <v-col cols="11" class="subcategory-title">
                  <h3>
                    {{
                      subcat[lang]
                        ? subcat[lang].name.toUpperCase()
                        : subcat[lang]
                    }}
                  </h3>
                </v-col>
                <div class="primary w-100 hr-subcategory"></div>
                <menu-items
                  :category="subcat"
                  :images="images"
                  :lang="lang"
                  @show-allergens="showAlergens = true"
                ></menu-items>
              </div>
            </v-row>
          </span>
        </v-tab-item>
      </v-tabs-items>
      <div class="primary footer">
        <div class="footer-content">
          <a class="footer-link" href="https://digimeni.si/" target="blank"
            >Digimeni</a
          >
          &copy;
          <a class="footer-link" href="https://foreach-labs.si/" target="blank"
            >Foreach Labs 2020</a
          >
        </div>
      </div>
    </v-container>
    <lang-selection-dialog
      :lang="lang"
      :dialog="dialog"
      :countries="countries"
      @change-lang="changeLang"
      @close-dialog="closeModal"
    ></lang-selection-dialog>
    <v-overlay :opacity="1" color="#EEEEEE" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import * as firebase from "firebase";

export default {
  components: {
    "menu-items": () => import("../meni/MenuItems"),
    alergens: () => import("../meni/Alergeni"),
    "lang-selection-dialog": () =>
      import("../dashboard/components/dialog/LangSelectionDialog"),
  },
  data() {
    return {
      showAlergens: false,
      tabs_visible: false,
      tab: null,
      overlay: true,
      lang: "sl",
      imgUrl: null,
      languages: [
        { text: "English", value: "en" },
        { text: "Slovenski", value: "sl" },
        { text: "German", value: "ge" },
      ],
      countries: [],
      dialog: false,
      images: {},
    };
  },
  async mounted() {
    //find menu if exist for given slug
    await this.$store.dispatch("FIND_MENI", {
      link: this.$route.params.link,
    });

    //redirect if menu doesn't exists
    if (
      this.$store.state.categories == null ||
      this.$store.state.categories.length == 0
    )
      this.$router.push({ name: "login" });

    //load menu parameters and settings
    this.load_meni().then(() => {
      this.lang =
        this.countries.length > 1
          ? this.countries[1].value
          : this.countries[0].value;
      this.selected_lang = this.lang;
      //get theme color and change it
      if (this.$store.state.meniColor) {
        this.$vuetify.theme.themes[this.isDark ? "dark" : "light"].primary =
          this.$store.state.meniColor;
      }
      this.$i18n.locale = this.lang;

      this.load_images().then(() => {
        this.overlay = false;
      });
    });
  },
  computed: {
    categories() {
      return this.$store.state.categories
        .filter((cat) => {
          return cat.parentId == null;
        })
        .sort((a, b) => {
          return a.index - b.index;
        });
    },
    company_name() {
      return this.$store.state.company_name;
    },
  },
  methods: {
    categoryVisible: (category) => {
      let visible = false;

      let dates = [];
      for (let i in category.dates) {
        dates.push(category.dates[i]);
      }

      if (dates.length == 0) visible = true;

      const current_date = new Date(new Date().toUTCString());
      dates.forEach((el) => {
        let cat_date = new Date(new Date(el.id).toUTCString());
        switch (el.repeat) {
          case "daily":
            visible = true;
            break;
          case "weekly": {
            const a = cat_date.getDay();
            const b = current_date.getDay();
            if (a == b) visible = true;
          }
          case "monthly": {
            const a = cat_date.getDate();
            const b = current_date.getDate();
            if (a == b) visible = true;
          }
        }
      });

      return visible;
    },
    subcategories(categoryId) {
      return this.$store.state.categories
        .filter((cat) => {
          return cat.parentId == categoryId;
        })
        .sort((a, b) => {
          return a.index - b.index;
        });
    },
    changeLang: function ({ lang }) {
      this.lang = lang;
      this.$i18n.locale = this.lang;
      this.dialog = false;
    },
    open_dialog: function () {
      this.selected_lang = this.lang;
      this.dialog = true;
    },
    toggle_alergens: function () {
      this.showAlergens = !this.showAlergens;
    },
    closeModal: function () {
      this.dialog = false;
    },
    hide_expansion_panel: function () {
      console.log("Click!");
      //document.getElementsByClassName("v-expansion-panel-content")[0].style.display = "none"
      //document.getElementsByClassName("v-expansion-panel-header--active")[0].classList.remove("v-expansion-panel-header--active");
    },
    load_meni: function () {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("GET_MENI", { id: this.$store.state.resource_id })
          .then((snapshot) => {
            let meni = snapshot.val();
            if (meni && meni.languages) {
              this.languages.forEach((lang) => {
                if (meni.languages.includes(lang.value))
                  this.countries.push(lang);
              });
            } else {
              this.countries.push({ text: "Slovenski", value: "sl" });
            }
            resolve();
          });
      });
    },
    load_images: async function () {
      return new Promise(async (resolve, reject) => {
        var storageRef = firebase.storage().ref();
        //get logo
        await storageRef
          .child("logo/" + this.$store.state.resource_id)
          .getDownloadURL()
          .then(
            (url) => {
              this.imgUrl = url;
            },
            function () {}
          );
        // Create a reference under which you want to list
        var listRef = storageRef.child("meni/" + this.$store.state.resource_id);
        // Find all the prefixes and items.
        await listRef
          .listAll()
          .then((res) => {
            res.items.forEach(async (itemRef) => {
              // All the items under listRef.
              await storageRef
                .child(itemRef.location.path_)
                .getDownloadURL()
                .then(
                  (url) => {
                    let s = itemRef.location.path_;
                    this.$set(
                      this.images,
                      s.substr(s.lastIndexOf("/") + 1),
                      url
                    );
                  },
                  function () {}
                );
            });
          })
          .catch(function (error) {});
        resolve();
      });
    },
  },
};
</script>

<style lang="css">
.meni-header {
  background-color: #2ec4b6;
}

.tabs {
  position: fixed;
  right: -500px;
  top: 0;
  z-index: 10;
  background: #f5f5f5;
  width: 20rem;
  height: 100%;
  transition: right 0.5s;
}
.tabs-visible {
  right: 0;
}

.header {
  position: fixed;
  z-index: 2;
  width: 100%;
}

.header-title {
  padding-top: 32px;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.lang-btn {
  margin: 0;
}

.back-btn {
  margin-right: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.tab-items {
  padding-bottom: 70px;
  padding-top: 150px;
  overflow: scroll;
  height: 100%;
}

.alergen-container {
  padding-bottom: 70px;
  overflow: scroll;
  height: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  color: white;
  z-index: 1;
}

.footer-content {
  margin: auto;
  text-align: center;
  height: 100%;
  vertical-align: middle;
  font-size: 16px;
  margin-top: 25px;
}

.footer-link {
  color: white !important;
  text-decoration: none;
}

.dialog-lang-title {
  font-size: 24px;
  font-weight: 400;
}

.radius-20 {
  border-radius: 20px;
}

.subcategory-title {
  margin: 10px !important;
  padding-bottom: 5px !important;
  margin-bottom: 0 !important;
  margin-top: 20px !important;
}

.hr-subcategory {
  height: 2px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (min-width: 800px) {
  .v-window-item {
    width: 55% !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
